import { Platform } from '@angular/cdk/platform';
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import {
  Inject,
  Injectable,
  makeStateKey,
  Optional,
  PLATFORM_ID,
  TransferState,
} from '@angular/core';
import { REQUEST } from '@app/shared/models/constants/tokens';
import { Request } from 'express';
import isMobile from 'is-mobile';

const isMobileKey = makeStateKey<boolean>('isMobile');

@Injectable({ providedIn: 'root' })
export class MobileDeviceService {
  private isMobile = false;

  private webkitVer: number;

  private isAndroid: boolean;

  private isGoogle: boolean;

  private isIPad: boolean;

  private androidDesktopMode: boolean;

  private iosDesktopMode: boolean;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject(REQUEST) private req: Request,
    private platform: Platform,
    private transferState: TransferState
  ) {}

  public get isMobileDevice() {
    return this.isMobile;
  }

  public get isApple() {
    return this.isIPad || this.iosDesktopMode || this.platform.IOS;
  }

  public detectDevicePlatform(): void {
    if (isPlatformServer(this.platformId)) {
      this.isMobile = isMobile({ ua: this.req });

      this.transferState.set(isMobileKey, this.isMobile);
    }

    if (isPlatformBrowser(this.platformId)) {
      this.webkitVer = parseInt(
        /WebKit\/([0-9]+)|$/.exec(navigator.appVersion)[1],
        10
      );

      this.isGoogle =
        this.webkitVer && navigator.vendor.indexOf('Google') === 0;

      this.isAndroid =
        this.isGoogle && navigator.userAgent.indexOf('Android') > 0;

      this.isIPad =
        navigator.userAgent.match(/Mac/) &&
        navigator.maxTouchPoints &&
        /Mac/.test(navigator.platform);

      this.androidDesktopMode =
        !this.isAndroid &&
        this.isGoogle &&
        navigator.platform.indexOf('Linux a') === 0 &&
        'ontouchstart' in this.document.documentElement;

      this.iosDesktopMode =
        navigator.vendor.indexOf('Apple') === 0 &&
        'ontouchstart' in this.document.body;

      this.isMobile =
        isMobile() ||
        this.transferState.get(isMobileKey, false) ||
        this.androidDesktopMode ||
        this.iosDesktopMode ||
        this.isIPad ||
        this.platform.ANDROID ||
        this.platform.IOS;
    }
  }
}
